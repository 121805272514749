import React, { useEffect } from "react";
import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigation,
  useRouteError,
} from "@remix-run/react";

import { Toaster } from "./components/ui/toaster.tsx";
//@ts-ignore
import styles from "./root.css?url";
import calenderStyle from "react-calendar/dist/Calendar.css?url";
import BackButtonListener from "./utils/plugins/backButtonListener.tsx";
import ClientOnly from "./utils/lib/client-only.tsx";
import util from "./utils/util.ts";
import PageLoader from "./components/ui/custom_ui/loader.tsx";
import { FilterProvider } from "~/context/store.tsx";
import { GenericErrorBoundary } from "./components/misc/error-boundary.tsx";
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap",
  },
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: calenderStyle },
];

export const meta: MetaFunction = () => {
  return [...util.baseMeta, ...util.defaultMeta];
};

interface Props {
  children: React.ReactNode;
  lang?: string;
}

function Document(props: Props) {
  const { children, lang = "en" } = props;
  // Google analytics
  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV === "prod") {
      // Load Google Analytics
      const gtagScript = document.createElement("script");
      gtagScript.src =
        "https://www.googletagmanager.com/gtag/js?id=GTM-WS64J4CK";
      gtagScript.async = true;
      document.head.appendChild(gtagScript);

      const gtagInitScript = document.createElement("script");
      gtagInitScript.id = "gtag-init";
      gtagInitScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'GTM-WS64J4CK', {
              page_path: window.location.pathname,
            });
          `;
      document.head.appendChild(gtagInitScript);
    }
  }, []);

  return (
    <html lang={lang} suppressHydrationWarning>
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Toaster />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  /**
   * NOTE: `useLoaderData` is not available in the Error Boundary.
   * The loader likely failed to run so we have to do the best we can.
   */
  return (
    <Document>
      <GenericErrorBoundary />
    </Document>
  )
}

function App() {
  const { state } = useNavigation();

  return (
    <Document>
      <FilterProvider>
        <Outlet />
        {state !== "loading" ? null : <PageLoader />}
        <ClientOnly fallback={null}>
          <BackButtonListener />
        </ClientOnly>
      </FilterProvider>
    </Document>
  );
}

export default withSentry(App);
