const env = import.meta.env.VITE_NODE_ENV
const PROD = env === 'prod'

export const employee = {
    DEFAULT_MANAGED_BY: {
        SHIPPER: PROD ? 2491 : 3, // PROD: PRIYADHARSHINI M
        CARRIER: PROD ? 2479 : 3  // PROD: VIKAS H
    },
    CONTACT: {
        SHARE: {
            name: "",
            mobile: "+971 509408799" // VIKAS H 
        }
    },
    ROLE: {
      ADMIN: 1,
      OPS: 2,
      SALES: 3,
      FINANCE: 4,
      OPS_MANAGER: 5
    }
}
