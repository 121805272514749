export const trip = {
  TRIP_PAYABLE: {
    TRIP_RATE: { id: 1, name: 'Trip rate' },
    LOADING_CHARGES: { id: 2, name: 'Loading charges' },
    UNLOADING_CHARGES: { id: 3, name: 'Unloading charges' },
    OTHER_CHARGES: { id: 4, name: 'Other charges' },
    CASH: { id: 5, name: 'Cash' },
    COMMISSION_FEE: { id: 6, name: 'Commission Fee' },
    LOADING_HALTING: { id: 7, name: 'Loading Halting' },
    UNLOADING_HALTING: { id: 8, name: 'Unloading Halting' },
    ONHOLD_CHARGES: { id: 9, name: 'On-Hold' },
    INSPECTION_CHARGE: { id: 10, name: 'Inspection Charge' },
    TAG_CHARGE: { id: 11, name: 'Tag Charge' },
    TOLL_CHARGE: { id: 12, name: 'Toll Charge' },
    ADJUSTMENT_CHARGE: { id: 13, name: "S/D Adjustment" },
    ENTRY_CHARGE: { id: 14, name: "Entry Charge" },
    BORDER_HALTING: { id: 15, name: "Border halting" },
    BORDER_CHARGE: { id: 16, name: "Border charge" }
  },
  ADVANCE_TYPE: {
    ADVANCE: 1,
    ADDITIONAL_ADVANCE: 2
  },
  TRIP_STATUS: {
    CONFIRMED: 1,
    REPORTED_AT_SOURCE: 2,
    INTRANSIT: 3,
    REPORTED_AT_DESTINATION: 4,
    DELIVERED: 5,
    INVOICED: 6,
    PAID: 7,
    RECEIVED: 8,
    CLOSED: 9,
    CANCELLED: 10
  },
  PAYMENT_STATUS: {
    INITIATED: 'Initiated',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
    REJECTED: 'Rejected'
  },
  INVOICE_DRAFT_STATUS: {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected'
  },
  INVOICE_DRAFT_TYPE: {
    CHARGES: 'Charges',
    REQUEST: 'Request Draft'
  },
  PAYMENT_TYPE: {
    ADVANCE: 'Advance',
    ADDITIONAL_ADVANCE: 'Additional Advance',
    BALANCE: 'Balance'
  },
  CUSTOMER_WRITE_OFF_TYPE: {
    POD_DELAY_LOST_WRITE_OFF: "Write off POD Delay/Missing",
    LATE_DELIVERY_WRITE_OFF: "Write off Late Delivery",
    LOADING_CHARGES_WRITE_OFF: "Write off Loading Charge",
    UNLOADING_CHARGES_WRITE_OFF: "Write off UnLoading Charge"
  },
  CREDIT_DEBIT_STATUS: {
    APPROVED: 1,
    PENDING: 2,
    REJECTED: 3
  },
  EXCESS_HEADER: {
    SHORTAGE_HEADER: "shortageCharge",
    POD_HEADER: "podLateFee",
    LATE_DELIVER_HEADER: "lateDelivery",
    TDS_FILED_BY_PARTNER_HEADER: "tdsFiledByPartner",
    LOADING_CHARGE_HEADER: "loadingCharge",
    UNLOADING_CHARGE_HEADER: "unloadingCharge"
  },
  TRIP_URL: {
    employee: "/app/employee/trips/",
    loads: "/app/carrier/trips/",
    book: "/app/shipper/trips/",
  },
  TIP_FILE_TYPE: {
    LR: 'Consignment Note',
    POD: 'Delivery Note',
    EVIDENCE: 'Evidence'
  },
  MAX_ALLOWED_CHARGE_REQUEST: 5000,
  COMMISSION_SCHEME: {
    commissionPercent: [5, 6, 7],
    advancePercent: [50, 65, 80],
    minCommission: 50
  },
  MIN_ALLOWED_PRICE: 10000,
  MAX_ALLOWED_PRICE: 30000,
}